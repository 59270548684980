
  import en from '~/assets/svg/Eng.svg'
  import ka from '~/assets/svg/Kaa.svg'
  import ru from '~/assets/svg/Ru.svg'
  import uz from '~/assets/images/Uz.jpg'
  export default  {
    name: 'LangSwitcher',
    data() {
      return {
        locale: this.$i18n.locale,
        isLang: false,
        localeImage: {
          uz: uz,
          kaa: ka,
          ru: ru,
          en: en
        }
      }
    },
    methods: {
      onChange(event) {
        this.$router.push(this.switchLocalePath(event.code))
        this.isLang = !this.isLang;
        this.locale = this.$i18n.locale
      },
    },
    mounted() {
    }
  }
  