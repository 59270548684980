
import LangSwitcher from '../core/lang-switcher.vue'
import mobileMenu from '../core/mobile-menu.vue'
import { mapState } from 'vuex'
import { socket } from '~/plugins/socket.client.js'
export default {
  name: 'Navbar',
  props: ['items'],
  components: {
    LangSwitcher,
  },
  data() {
    return {
      isProfileOpened: false,
      isActive: false,
      navbar: [
        {
          id: 1,
          name: 'about',
          route: '/about',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 2,
          name: 'e-learning',
          route: '/e-learning',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 3,
          name: 'agri-business',
          route: '/agri-business',
          current: false,
          children: [
            { name: 'agro-location', route: '/agri-business', current: false },
            { name: 'agro-tools', route: '/agro-tools', current: false },
          ],
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 4,
          name: 'agri-finance',
          route: '/agri-finance',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 5,
          name: 'agri-market',
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
          route: '/agri-market',
          current: false,
        },
        {
          id: 6,
          name: 'news',
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
          route: '/new',
          current: false,
        },
        {
          id: 7,
          name: 'advisory',
          route: '/advisory',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
      ],
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
    }),
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 400) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    async logOut() {
      await socket.emit('leave', {
        username: this.currentUser.username,
        user_id: this.currentUser.id,
      })
      this.isProfileOpened = false
      await localStorage.removeItem('local')
      await localStorage.removeItem('user_info')
      await this.$auth.logout()
    },
    toMyChats() {
      this.isProfileOpened = false
      this.$router.push({ path: this.localePath('/chats') })
    },
    openProfile() {
      this.isProfileOpened = !this.isProfileOpened
    },
    openMobileMenu() {
      this.$showPanel({
        component: mobileMenu,
        openOn: 'left',
        width: (window.innerWidth * 3) / 4,
      })
    },
    menuChildClicked(menu, child) {
      this.navbar.forEach(el=> {
        if(el.name === menu.name) {
          el.current = false
        }

      })
      menu.children.forEach((ch) => {
        if (child === ch) {
          ch.current = true
        } else {
          ch.current = false
        }
      })
      this.$router.push(this.localePath(child.route))
      return child
    },
    menuWithChildClicked(menu) {
      this.navbar.forEach((navbar) => {
        if (menu === navbar) {
          navbar.current = !navbar.current
          navbar.children.forEach((child) => {
            child.current = false
          })
        } else {
          navbar.current = false
        }
      })
      return menu
    },
    signIn() {
      this.$router.push({
        path: this.localePath('/login'),
      })
    },
  },
}
