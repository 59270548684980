
import { mapState } from 'vuex'
import { socket } from '~/plugins/socket.client'

export default {
  name: 'MobileMenu',
  data() {
    return {
      navbar: [
        {
          id: 1,
          name: 'about',
          route: '/about',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 2,
          name: 'e-learning',
          route: '/e-learning',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 3,
          name: 'agri-business',
          route: '/agri-business',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
          children: [
            { name: 'agro-location', route: '/agri-business', current: false },
            { name: 'agro-tools', route: '/agro-tools', current: false },
          ],
        },
        {
          id: 4,
          name: 'agri-finance',
          route: '/agri-finance',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
        {
          id: 5,
          name: 'agri-market',
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
          route: '/agri-market',
          current: false,
        },
        {
          id: 534,
          name: 'news',
          route: '/new',
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
          current: false,
        },
        {
          id: 6,
          name: 'advisory',
          route: '/advisory',
          current: false,
          iconDown: 'bx bx-chevron-down',
          iconUp: 'bx bx-chevron-up',
        },
      ],
    }
  },
  methods: {
    menuChildClicked(menu, child) {
      menu.children.forEach((ch) => {
        if (child === ch) {
          ch.current = true
        } else {
          ch.current = false
        }
      })
      this.$router.push(this.localePath(child.route))
      return child
    },
    menuWithChildClicked(menu) {
      this.navbar.forEach((navbar) => {
        if (menu === navbar) {
          navbar.current = !navbar.current
          navbar.children.forEach((child) => {
            child.current = false
          })
        } else {
          navbar.current = false
        }
      })
      return menu
    },
    signIn() {
      this.$router.push({
        path: this.localePath('/login'),
      })
    },
    closePanel() {
      this.$emit('closePanel', {})
    },
    async logOut() {
      await socket.emit('leave', {
        username: this.user_name,
        user_id: this.user_id,
      })
      this.isProfileOpened = false
      await localStorage.removeItem('local')
      await localStorage.removeItem('user_info')
      await this.$auth.logout()
      this.closePanel()
      this.$router.push({path: this.localePath('/')})
    },
    toMyChats() {
      this.isProfileOpened = false
      this.closePanel()
      this.$router.push({ path: this.localePath('/chats') })
    },
    openProfile() {
      this.isProfileOpened = !this.isProfileOpened
    },
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.loggedIn,
      currentUser: (state) => state.auth.user,
      user_id: (state) => state.auth.user.id,
      user_name: (state) => state.auth.user.username
    }),
    }
}
